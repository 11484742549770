import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Trabajo } from "../components/Trabajo"

const ComoTrabajamos = () => (
    <Layout>
        <SEO title="Servicios" />
        <Trabajo />
    </Layout>
)

export default ComoTrabajamos

