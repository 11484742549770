import React from 'react'
import './Trabajo.css'
import trabajo from '../images/comotrabajamos1m.jpg'

export const Trabajo = () => {
    return (
        <div className="trabajo">
            <div className="trabajo__content">


                <h3 style={{ padding: '20px' }} >Disponemos de taller propio para hacer todo tipo de arreglos:</h3>
                <div className="trabajo__container" >
                    <div className="trabajo__info" >
                        <h3>Joyería</h3>
                        <p>Diseños de joyas</p>
                        <p>Compostura</p>
                        <p>Arrreglos de todo tipo</p>
                        <br />
                        <h3>Relojería</h3>
                        <p>Colocación de cristales</p>
                        <p>Pilas</p>
                        <p>Reparaciones</p>
                    </div>
                    <div className="trabajo__img" >
                        <img src={trabajo} alt="trabajo" />
                    </div>
                </div>
            </div>
        </div>
    )
}
